.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main_bg {
    color: #f5f5f5;
    padding: 10% ;
    padding-top: 5%;
}

.main_bg h1, .header h1 {
    /* text-align: center;
    color: #333;
    padding-bottom: 40px; */
    color: #16224a;
    padding-bottom: 20px;
    font-family: 'system-ui';
}

.reset_bg {
    background-size: cover;
    height: 100vh;
    color: #f5f5f5;
    padding: 10% 30%;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon svg {
    position: absolute;
    left: 14px;
    bottom: 0;
    top: 0;
    margin: auto;
    color: #666;
}
.loginFormContain h1{
    text-align: center;
}
.loginContain .loginBG{
    background-position: bottom right;
}
.login_desc{
    text-align: center;
    color: #6b757d;
    padding-bottom: 4%;
}
.loginFormContain img{
    width: 100%;
    padding-bottom: 2%;
}

.inputWithIcon .form-control {
    padding-left: 44px;
}

.insideForm {
    padding: 0px 75px;
}

.customButton {
    background: #002baee8;
    border-color: #3c335f;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
}

.customButton.focus,
.customButton:focus {
    box-shadow: 0 0 0 0.2rem rgb(103 58 183 / 48%);
}

.btn.btn-secondary.customButton:focus,
.btn.btn-secondary.customButton:hover,
.btn.btn-secondary.customButton:active {
    background:#001e3b;
    border-color:#001e3b;
}

.insideForm .btn-secondary {
    color: #fff;
    background-color:#00376E;
    border-color:#00376E;
}

.main_bg .tab-content,
.reset_bg .reset_pwd {
    background-color: #16224a;
    /* padding-top: 30px;
    padding-bottom: 30px; */
    border-radius: 4px;
}

.main_bg .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #16224a;
}

.main_bg .nav-tabs .nav-link {
    background-color:#888888;
    border-bottom: 1px solid #fff;
    color: #fff;
}

.main_bg .nav-tabs .nav-link {
    border-left: 1px solid #fff;
}

.validationError {
    color:red;
    margin-bottom: 0;
    font-size: 14px;
    display: block;
}
.contentWrapper form .validationError{
    color: red;
    margin-bottom: 0;
    font-size: 14px;
    display: block;
}
li.nav-item {
    border-bottom: 1px solid #ccccccc7;
}
.main_bg .btn-secondary.disabled,
.main_bg .btn-secondary:disabled {
    background-color: #11719b;
    border-color: #11719b;
    opacity: .75;
}

.main_bg .toast_msgs,
.reset_bg .toast_msgs {
    max-width: 100%;
    text-align: center;
}

.main_bg .error,
.reset_bg .error,
.riskinput .error {
    /* background-color: #ff0000c7; */
    border: 2px solid red;
}

.main_bg .success,
.reset_bg .success,
.riskinput .success {
    /* background-color: green; */
    border: 2px solid green;
}

.two_date_width {
    display: inline-block;
    width: 49%;
}

.eyeicon {
    position: absolute;
    right: 50px;
    bottom: 0;
    top: 0;
    margin: auto;
    color: #666;
}

.reset_heading {
    text-align: center;
    font-size: 30px;
    padding-bottom: 4%;
}

.reset_pwd .customButton {
    margin-top: 3%;
}

.header .navbar-collapse {
    padding: 10px 20px;
    min-height: 80px;
    border-bottom: 1px solid #ccc;
}

.black {
    background-color: #f5f7fa;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: #F5F7FA;
    /* font-size: 14px; */
    font-size: -1rem !important;
    letter-spacing: 0.3px;
}

.dropdown-item {
    font-size: 14px;
    padding: 8px 15px;
}

.dropdown-item svg {
    width: 16px;
    margin-right: 5px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.contentWrapper .css-mqoqhe {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}
.histograph{
    opacity: 0.2; 
}
/*Global CSS*/

.form-control {
    height: auto;
    padding: 8px 15px;
}
/* .pageContain .for_modal summary{
    display: none;
} */
label {
    margin-bottom: 3px;
}

.color-light-black {
    color: #555;
}

.dropdown-menu {
    min-width: 180px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .1);
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon svg {
    position: absolute;
    left: 14px;
    bottom: 0;
    top: 0;
    margin: auto;
    color: #666;
}

.inputWithIcon .form-control {
    padding-left: 44px;
}

.customButton {
    background: #ee3642;
    border-color: #ee3642;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
}

.customButton.focus,
.customButton:focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 54, 66, 0.5);
}

.btn.btn-danger.customButton:focus,
.btn.btn-danger.customButton:hover,
.btn.btn-danger.customButton:active {
    background: #e8111f;
    border-color: #e8111f;
}

.table-responsive,
.default-shadow {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.width100 {
    width: 100px;
}
.width110{
    min-width: 110px;
}
.image_lgn{
    width: 75%;
    margin: 0 auto;
}
.modal-footer .btn-primary{
    background-color: #16224a;
    border-color: #16224a;
}
.modal-content .modal-header{
    background: #16224a;
}
.pageTitleHeading.lagafg{
    margin-left: 0px;
}
/*Global CSS*/


/*Keyframe Animations Start*/

.icon-anim-badgePulse {
    animation: BadgePulse 1.8s infinite;
    animation-fill-mode: both;
}

@keyframes BadgePulse {
    0% {
        box-shadow: 0 0 0 0 rgba(56, 142, 60, .4)
    }
    70% {
        box-shadow: 0 0 0 10px rgba(56, 142, 60, 0)
    }
    100% {
        box-shadow: 0 0 0 20px rgba(56, 142, 60, 0)
    }
}

.icon-anim-pulse {
    animation: pulse_animation 1000ms infinite;
    animation-timing-function: linear;
}

@keyframes pulse_animation {
    0% {
        transform: scale(1)
    }
    30% {
        transform: scale(1.1)
    }
    40% {
        transform: scale(1.21)
    }
    50% {
        transform: scale(1)
    }
    60% {
        transform: scale(1)
    }
    70% {
        transform: scale(1.09)
    }
    80% {
        transform: scale(1.05)
    }
    100% {
        transform: scale(1)
    }
}

.icon-anim-swing {
    animation: swing 1s infinite;
    animation-fill-mode: both;
    transform-origin: top center;
}

@keyframes swing {
    20% {
        transform: rotate3d(0, 0, 1, 15deg)
    }
    40% {
        transform: rotate3d(0, 0, 1, -10deg)
    }
    60% {
        transform: rotate3d(0, 0, 1, 5deg)
    }
    80% {
        transform: rotate3d(0, 0, 1, -5deg)
    }
    to {
        transform: rotate3d(0, 0, 1, 0deg)
    }
}


/*Keyframe Animations End*/


/*Table Start*/

.defaultTable {
    background: #fff;
    margin: 0;
}

.defaultTable thead th,
.defaultTable tbody td {
    padding: 10px;
}

.actionBtns button {
    padding: 3px;
    margin: 0 2px;
    line-height: 10px;
}

.actionBtns button.text-danger {
    color: #ff0000 !important;
}


/*Table End*/


/*Login and Signup Start*/

.loginContain,
.signupContain {
    display: table;
    width: 100%;
    table-layout: fixed;
    min-height: 100vh;
    background: #fff;
}

.loginContain .loginFormContain,
.loginContain .loginBG,
.signupContain .signupInfoContain,
.signupContain .signupFormContain {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}

.loginContain .loginFormContain {
    position: relative;
    z-index: 2;
}

.signupContain .signupInfoContain {
    width: 40%;
}

.signupContain .signupFormContain {
    width: 60%;
}

.loginFormContain form {
    /* max-width: 400px; */
    margin: auto;
    padding: 20px;
}
.loginForm{
    max-width: 500px !important;
}

.loginFormContain form input {
    box-shadow: 0 0 2px 1px rgba(165, 179, 187, 0.5);
}

.loginFormContain .row a,
.loginFormContain p a {
    font-size: 14px;
}

.loginLogo {
    width: 100%;
    max-width: 220px;
}

.loginBG {
    background-image: linear-gradient(to left, rgb(60 65 70 / 33%), rgb(22 34 75 / 60%)), url(/src/MainBg1.jpg);
    background-size: cover;
    height: 100vh;
    position: fixed;
    height: 800px;
}

.login-dot-img {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 170px;
    height: 170px;
    background-size: contain;
}
.search-input {
    padding: 10px 10px;
    height: 52px;
    position: relative;
  }
.for_modal .css-15xdje5{
    position: absolute;
    z-index: 1;
    top: 35%;
    left: 0;
    right: 0;
}
.riskinput .css-15xdje5{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    right: 0;
}
.entire_card{
    padding-bottom: 35px;
}
.for_modal .mb-2.card{
    display: inline-block;
    margin: 0px 20px 20px 0px;
    text-align: center;
}
.dashb_select{
    padding-bottom: 40px;
}
.dashb_select .pageTitleHeading{
    display: inline-block;
}
.dashb_select .float_select{
    padding-top: 10px;
    display: inline-block;
    margin-left: 40px;
}
  .search-input::before {
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==');
    display: block;
    position: absolute;
    width: 15px;
    z-index: 3;
    height: 15px;
    font-size: 20px;
    top: 11px;
    left: 16px;
    line-height: 32px;
    opacity: 0.6;
  }

  .form-group .css-2b097c-container{
    width: 85%;
  }
  
  .search-input > input {
    width: 100%;
    font-size: 18px;
    border: none;
    line-height: 22px;
    padding: 5px 10px 5px 25px;
    height: 32px;
    position: relative;
  }
  
  .search-input > input:focus {
    outline: none;
  }

.App {
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.AppTitle-title {
    font-family: 'Athiti', sans-serif;
    font-size: 4rem;
}

.AppTitle-github {
    font-family: 'Athiti', sans-serif;
    margin-top: -1rem; 
    display: flex; 
    justify-content: center;
}

.AppTitle-github a {
    text-decoration: none;
}

.CategoryTitle {
    font-family: 'Athiti', sans-serif;
}

.signupFormContain form {
    max-width: 600px;
    background: #fff;
    padding: 30px 40px;
    margin: 20px auto;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}
.mb-2.card .card-header, .mb-2.card .card-body {
    color: #fff;
}
.signupInfoContain .signupInfoInner {
    max-width: 400px;
    margin: auto;
    padding: 20px;
}
.card-body .card-text{
    text-decoration: underline;
    cursor: pointer;
}
.signupContain {
    background-size: 100% 100%;
}

.sigupInfoList {
    display: table;
    width: 100%;
    padding-bottom: 20px;
}

.sigupInfoList svg {
    display: table-cell;
    width: 20px;
    margin-right: 15px;
    color: #65D6AD;
}

.sigupInfoListInner {
    display: table-cell;
    width: 100%;
}


/*Login and Signup End*/


/*Navbar Start*/

.navbar {
    padding: 0;
}

.navbar-brand {
    width: 260px;
    padding: 0;
    margin: 0;
    background: #fff;
    min-height: 80px;
    padding: 13px 0 0 20px;
    box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.1);
}

.navbar-brand img {
    height: 50px;
    width: 215px;
}

.header .navbar-collapse {
    padding: 10px 20px;
    min-height: 80px;
    border-bottom: 1px solid #ccc;
}

.navbar-nav .userDropdown>.nav-link {
    padding: 0 25px 0 0;
    color: #333;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.navbar-nav .userDropdown>.nav-link img {
    height: 46px;
    width: 46px;
    margin-right: 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.navbar-nav .userDropdown>.nav-link:after {
    position: absolute;
    right: 10px;
    max-height: 2px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.notificationBtn {
    position: relative;
    min-width: 46px;
}

.notification-has-value:before {
    content: '';
    background: #388E3C;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgb(156, 142, 60, 0.4);
    animation: BadgePulse 1.8s infinite;
    animation-fill-mode: both;
    position: absolute;
    top: -7px;
    right: -7px;
    border: 3px solid #f5f7fa;
}

.notification-has-value svg {
    animation: swing 1s infinite;
    animation-fill-mode: both;
    transform-origin: top center;
    margin-top: 4px;
}

.navbar-toggler.menuToggleBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #007bff;
    color: #fff;
    border: none;
    padding: 7px;
    font-size: 16px;
}


/*Navbar End*/


/*Sidebar Start*/

.sidebar {
    background: #fff;
    position: relative;
    z-index: 5;
    box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.sidebarInner {
    width: 100%;
    display: block!important;
}

.sidebar.navbar .sidebarInner a {
    padding: 12px 25px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
}

.sidebar.navbar .sidebarInner a.active {
    background: #e6e6e6;
}

.sidebar.navbar .sidebarInner a svg {
    width: 16px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

.sidebarToggleBtn {
    position: absolute;
    top: -55px;
    left: 10px;
    border: none;
    background: transparent;
    padding: 10px;
}

.sidebarToggleBtn div {
    width: 24px;
    height: 24px;
    position: relative;
}

.sidebarToggleBtn:hover,
.sidebarToggleBtn:focus {
    background: transparent;
    outline: none;
}

.sidebarToggleBtn span {
    width: 100%;
    height: 2px;
    background-color: #3f6ad8;
    border-radius: 10px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    left: 0;
    top: 4px;
}

.sidebarToggleBtn span:before,
.sidebarToggleBtn span:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #3f6ad8;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 6px;
    transition: opacity 0.125s 0.275s ease;
}

.sidebarToggleBtn span:after {
    top: 12px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sidebarToggleBtn:not(.collapsed) span {
    transform: translate3d(0, 6px, 0) rotate(135deg);
    transition-delay: 0.075s;
}

.sidebarToggleBtn:not(.collapsed) span:before {
    transition-delay: 0s;
    opacity: 0;
}

.sidebarToggleBtn:not(.collapsed) span:after {
    transform: translate3d(0, -12px, 0) rotate(-270deg);
    transition-delay: 0.075s;
}


/*Sidebar End*/

.content {
    padding: 20px;
}


/*Page title wrapper Start*/

.pageTitleWrapper {
    display: table;
    width: 100%;
    table-layout: auto;
    color: #495057;
    margin-bottom: 20px;
}

.pageTitleHeading,
.pageTitleButtons {
    display: table-cell;
    vertical-align: middle;
  
}

.pageTitleHeading {
    font-size: 22px;
}

.pageTitleHeading .pageTitleHeadingIcon {
    background: #fff;
    border-radius: 4px;
    margin-right: 15px;
    line-height: 40px;
    width: 42px;
    height: 42px;
    color:#0d2869;
    display: inline-block;
    text-align: center;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.pageTitleHeading .pageTitleHeadingIcon svg {
    opacity: 0.8;
    top: -3px;
    position: relative;
}

.col .btn-primary.focus, .col .btn-primary:focus{
    color: #fff;
    background-color: #001e3b;
    border-color: #001e3b;
}

.pageTitleButtons .btn {
    text-transform: none;
    font-size: 14px;
    margin: 0;
    margin-left: 5px;
    box-shadow: none;
    font-weight: 500;
    color: white;
    line-height: 36px;
    padding: 0 30px;
}

.pageTitleButtons .btn.btn-primary {
    box-shadow: 0 0.125rem 0.625rem rgba(33, 150, 243, 0.4), 0 0.0625rem 0.125rem rgba(33, 150, 243, 0.4);
}
form .btn-primary{
    background-color:#0F2869;
    border-color:#0F2869;
}
form .btn-primary:hover{
    background-color: #001e3b;
    border-color: #001e3b;
}
form .btn-outline-primary:hover{
    background-color:#0d2869;
    border-color:#0d2869;
}
form .btn-outline-primary{
    color:#0d2869;
    border-color:#0d2869;
}
/*Page title wrapper End*/

.notificationList {
    display: table;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 15px;
}
nav .form-control:focus{
    border-color: #16224a61;
}
.notificationListIcon {
    display: table-cell;
    width: 60px;
    background: #ccc;
    vertical-align: middle;
    text-align: center;
    font-size: 24px;
    line-height: 20px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.notificationListText {
    display: table-cell;
    padding: 9px 20px;
}

.phoneBookList {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    display: table;
    width: 100%;
    padding: 16px;
}

.phoneBookList img {
    display: table-cell;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    vertical-align: top;
}

.phoneBookList .phoneBookText {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    padding-left: 20px;
}

.phoneBookText h6,
.phoneBookText p {
    margin: 0;
}

.for_modal {
    position: relative;
}

.for_modal .delete_popup {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.delete_popup .modal-body svg {
    height: 45px;
    background-color: #fff;
    color: red;
    width: 100%;
}

.delete_popup .modal-body h3 {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 0;
    font-size: 22px;
}

.delete_popup .modal-body p {
    text-align: center;
}

.delete_popup .modal-header .close {
    margin: 0;
}
.abv_img_alg{
    text-align: center;
}
.abv_img_alg img{
    width: 200px;
    height: 200px;
}

/*Modal Start*/

.modal-content {
    border: none;
    border-radius: 3px;
}

.modal-header {
    padding: 12px 20px;
    background: #007bff;
    color: #fff;
}

.modal-header .modal-title {
    font-size: 16px;
}

.modal-header .close {
    margin: 0;
    position: relative;
    right: 0;
    top: 0;
    opacity: 1;
    color: #fff;
    text-shadow: none;
    padding: 10px 20px 14px 20px;
}

.modal-body {
    padding: 10px 20px;
}

.modal-footer {
    padding: 10px 16px;
}

.modal-footer .btn {
    margin-top: 0;
    margin-bottom: 0;
}


/*Modal End*/

.col .radio_form {
    padding-right: 2%;
    display: inline-block;
}

.riskForm .inputWithIcon .form-control {
    padding-left: 16px;
}

.riskForm .row {
    padding: 8px 0px;
    margin-bottom: 10px;
}

.risk_sub {
    border-bottom: 1px solid #ccc;
    width: 94%;
}

.txt_ara_cls {
    resize: none;
}

.txt_dtls {
    font-size: 13px;
    color: #555;
}

.inputWithIcon .react-date-picker__wrapper {
    width: 260px;
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #fff;
}

.inputWithIcon .react-date-picker__clear-button svg {
    right: 5px;
    left: unset;
}

.inputWithIcon .react-date-picker__calendar-button svg {
    right: 0;
    left: 170px;
}

.react-date-picker__wrapper button:focus {
    outline: unset;
}

.riskinput .toast_msgs {
    position: absolute;
    margin: 0 auto;
    text-align: center;
    right: 0;
    left: 0;
    /* color: #fff; */
}

.table_scroll {
    padding: 0px 20px;
}

.cntrl_col {
    min-width: 98px;
}

.date_wdt {
    min-width: 108px;
}

.right_align {
    text-align: right;
}

.center_align {
    text-align: center;
}

.actionBtns button {
    padding: 3px;
    margin: 0 2px;
    line-height: 10px;
}

.actionBtns button.text-danger {
    color: #ff0000 !important;
}

.all_risk_hub .pageTitleHeading {
    display: inline-block;
}

.all_risk_hub .device_navbar {
    float: right;
    margin-left: 50px;
}

.table_scroll {
    padding: 20px 20px 0px 20px;
}

.all_risk_hub .userclear {
    margin-left: 8px;
}

.empty_records {
    text-align: center;
}

.disabled a {
    pointer-events: none;
}

.disabled {
    cursor: not-allowed;
}

.pagination li a {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color:#0F2869;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination {
    margin-top: 15px;
    float: right;
}

.pagination>.active>a {
    z-index: 3;
    color: #fff;
    background-color:#0F2869;
    border-color:#0F2869;
    cursor: default;
}
nav .navbar-brand{
    text-align: center;
    padding-left: 0px;
    padding-top: 0px;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background-color:#162248;
    border-color:#162248;
}
.navbar-light .navbar-brand img{
    width: 100px;
    height: 100px;
}
/**
 * Main wrapper
 */

.select-search {
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}


/**
* Value wrapper
*/

.select-search__value {
    position: relative;
    z-index: 1;
    /* min-width: 500px; */
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 6px);
    right: 14px;
    width: 7px;
    height: 7px;
}


/**
* Input
*/

.select-search__input {
    display: block;
    height: 38px;
    width: 100%;
    padding: .375rem .75rem;
    background: #fff;
    border: 1px solid transparent;
    border: 1px solid #ced4da;
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 1.5;
    -webkit-appearance: none;
    color: #495057;
    font-weight: 400;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}


/**
* Options wrapper
*/

.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}


/**
* Options
*/

.select-search__options {
    list-style: none;
    padding-left: 0px;
}


/**
* Option row
*/

.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}


/**
* Option
*/

.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 32px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background:#162248;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background:#162248;
    color: #fff;
}


/**
* Group
*/

.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    /* padding: 8px 16px; */
}


/**
* States
*/

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}


/**
* Modifiers
*/

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 2px solid #495057;
    border-bottom: 2px solid #495057;
    pointer-events: none;
}

.top_search{
    padding: 20px 0px;
}

.device_navbar .btn_redirection{
    background-color: #0d2869;
    color: #fff!important;
    margin-left: 15px;
}

.device_navbar .btn_redirection:hover{
    text-decoration: none;
}

.pageContain .contentWrapper form .upr_valid{
    display: inline-block;
    padding-left: 10px;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #ccc;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.inputWithIcon .trianinv {
    height: 100px;
}
.dropdown-menu-right .dropdown-item.active, .dropdown-menu-right .dropdown-item:active{
    background-color: #0d2869;
}

/*Media Queries for responsive*/

@media(min-width:768px) {
    .pageContain {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    .sidebar,
    .contentWrapper {
        display: table-cell;
        vertical-align: top;
    }
    #root .header {
        padding: 0;
    }
    .pageContain .sidebar {
        width: 260px;
        display: table-cell;
    }
    .sidebarInner {
        min-height: calc(95vh - 80px);
    }
    #root .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .pageTitleWrapper {
        margin-bottom: 2%;
    }
    form.riskForm {
        padding-left: 2%;
        padding-bottom: 2%;
    }
    .riskForm .inputWithIcon {
        width: 85%;
    }
}
.navbar {
    padding: 0px !important;
}
@media(max-width:768px) {
    .navbar {
        background: #fff;
        display: block;
        box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.1);
    }
    .header .navbar-collapse {
        border: none;
    }
    .navbar-brand {
        background: none;
        box-shadow: none;
        display: block;
        max-width: 180px;
        margin: auto;
        min-height: auto;
        padding: 10px;
    }
    #navbarToggleTop {
        position: absolute;
        width: calc(100% - 40px);
        background: #fff;
        z-index: 11;
        flex-basis: auto;
        display: flex;
        margin: 10px 20px;
        border-radius: 3px;
        border: 1px solid #ccc;
        padding: 10px;
        min-height: auto;
    }
    #navbarToggleTop.show {
        display: flex;
    }
    #navbarToggleTop:not(.show) {
        display: none;
    }
    #navbarToggleTop .navbar-nav {
        flex-direction: row;
    }
    #navbarToggleTop .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-brand img {
        max-width: 100%;
    }
    .loginContain .loginFormContain,
    .loginContain .loginBG,
    .signupContain .signupInfoContain,
    .signupContain .signupFormContain {
        width: 100%;
    }
    .loginContain .loginBG {
        display: none;
    }
    .signupInfoContain {
        display: table-row;
    }
    .signupInfoContain .signupInfoInner {
        padding-bottom: 0px;
    }
    .signupFormContain form {
        margin-top: 0;
    }
}


/* My profile */

.align_Form {
    width: 75%;
    margin: 0 auto;
    padding-top: 5%;
}

.updatemyprofilebutton {
    width: 200px !important;
    float: right !important;
}

.search_form {
    margin: 0 auto !important;
    margin-left: 50px;
   
}


.adduserbutton{
    position: relative;
    left: -20px;
}
.loginBG {
    /* background: url(/loginBG.jpg) no-repeat bottom left; */
    background-size: cover;
    height: 100vh;
    position: fixed;
}
.width150{
    min-width: 150px;
}

.width200{
    min-width: 200px;
}
.width300{
    min-width: 300px;
}
.width400{
    min-width: 400px;
}
.zohoSuccessmsg{
    border: 2px solid green;
}

.projectNameField{
    max-width: 100%;
}
.projectNameField84{
    max-width: 84.5%;
}

.zoholinkBtn {
    float: right;
    margin-left: auto!important;
    background-color: #0f2869 !important;
    color: white !important;
    margin-right: 20px;
}
.marks{
    width: 648px !important;
    height: 504px !important;
}
summary{
    display: none !important;
}
.noDataFound{
    text-align: center;
    margin-top: 17%;
    color: gray;
}
.currencyTypeTexts{
    font-size: 12px !important;
    font-weight: bolder;
    margin-left: 5px;
}

.height70 { 
    min-height: 70px !important;
}

.switch {	
    position: relative;	
    display: inline-block;	
    width: 60px;	
    height: 34px;	
  }	
  	
  .switch input { 	
    opacity: 0;	
    width: 0;	
    height: 0;	
  }	
  	
  .slider {	
    position: absolute;	
    cursor: pointer;	
    top: 0;	
    left: 0;	
    right: 0;	
    bottom: 0;	
    background-color: #ccc;	
    -webkit-transition: .4s;	
    transition: .4s;	
  }	
  	
  .slider:before {	
    position: absolute;	
    content: "";	
    height: 26px;	
    width: 26px;	
    left: 4px;	
    bottom: 4px;	
    background-color: white;	
    -webkit-transition: .4s;	
    transition: .4s;	
  }	
  	
  input:checked + .slider {	
    background-color: #2196F3;	
  }	
  	
  input:focus + .slider {	
    box-shadow: 0 0 1px #2196F3;	
  }	
  	
  input:checked + .slider:before {	
    -webkit-transform: translateX(26px);	
    -ms-transform: translateX(26px);	
    transform: translateX(26px);	
  }	
  	
  /* Rounded sliders */	
  .slider.round {	
    border-radius: 34px;	
  }	
  	
  .slider.round:before {	
    border-radius: 50%;	
  }	
  .radio-toolbar input[type="radio"] {	
    opacity: 0;	
    position: fixed;	
    width: 0;	
  }	
  .radio-toolbar label {	
    display: inline-block;	
    background-color: #ffffff;	
    padding: 18px 21px;	
    font-family: sans-serif, Arial;	
    font-size: 16px;	
    border: 2px solid #444;	
    border-radius: 4px;	
    min-width: 98px;
    min-height: 100px;
}	
.radio-toolbar input[type="radio"]:checked + label {	
    background-color:#a7d6f7;	
    border-color: rgb(78, 112, 78);	
}	
.radio-toolbar input[type="radio"]:focus + label {	
    border: 2px dashed #444;	
}	
.block {	
    display: block;	
    width: 50%;	
    border: none;	
    background-color: #132869;	
    padding: 134px 1px;	
    font-size: 16px;	
    cursor: pointer;	
    text-align: center;	
}

.checkmark {
    display:inline-block;
    /* width: 34px;
    height:22px; */
    padding: 18px 21px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width: 6px;
    height: 52px;
    background-color: #0f15f1;
    left: 11px;
    top: -13px;
    opacity: 0.4;
}

.checkmark_kick {
    position: absolute;
    width: 24px;
    height: 7px;
    background-color: #0f15f1;
    left: -13px;
    top: 32px;
    opacity: 0.4;
}

.straighline {
    display: 'block';
    height: 0;
    padding: 0;
    border-top: 1px solid #111111;
    border-bottom: 1px solid #292929;
}

.wrap {
    /* Your styling. */
    position: absolute;
    z-index: 999999;
    right: 0;
    height: 60%;
    text-align: center;

    /* Solution part I. */
    display: table;
}

/* Solution part II. */
.inside {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}


.device_navbar{
    width: 100%;
}

.device_navbar > .form-inline,
.device_navbar > .form-inline input{
    flex: 1;
}

.subscriptionbutton {
    float: right;
   margin-right: 5px;
    background-color: #0f2869 !important;
    color: white !important;
    margin-left: 20px;
}

.subscriptionbutton1{
    float: right;
    margin-right: 4px;
     background-color: #0f2869 !important;
     color: white !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}